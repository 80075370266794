import React, { Component } from "react";

class Container extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let className = `container-fluid px-3 px-md-2 px-sm-3 px-lg-3 `;
		let childClassName = `px-1 px-md-1 px-lg-3`;
		if(this.props.className) className += this.props.className;
		if(this.props.childClassName) childClassName += this.props.childClassName;
    	return(
    		<>
				<div className={className}>
					{this.props.imageComp}
					<div className={childClassName}>
						{this.props.children}
					</div>
				</div>
			</>
    	)
	}
}

export default Container;