import React from "react";
import Link from "next/link";
import { useRouter } from 'next/router'
import AuthContext from "../../contexts/AuthContext";
import { hostEventUrl } from "../../config/config";
// import { Auth } from "aws-amplify";
import { toast } from "react-toastify";
toast.configure();

const MobileNavbar = ({mobileNavbar}) => {
	const authContext = React.useContext(AuthContext);
	const currentPage = useRouter();
	const page = currentPage.pathname;
	let [cookiePopupHeight, setCookiePopupHeight] = React.useState();

	const changeClass = (currPage) => {
		if (currPage === page) return `nav-buttons-active`
		return `nav-buttons`
	}
	React.useEffect(() => {
		if (document.getElementById('cookie-popup-container') != null) {
			const height = document.getElementById('cookie-popup-container').clientHeight;
			setCookiePopupHeight(height);
			window.addEventListener('resize', () => {
				if (document.getElementById('cookie-popup-container') != null) {
					const height = document.getElementById('cookie-popup-container').clientHeight;
					setCookiePopupHeight(height);
				}
			})
		}
	}, [])

	return (
		<>
		{mobileNavbar && 
			(<div className="d-block d-md-none fixed-bottom app-responsive-header py-2">
				<div className="row menu_items_center">
					<div className="col-3 text-center">
						<Link href="/">
							<a>
								<span className={changeClass('/')} id="home">
									<i className="far fa-home"></i>
									<div className="app-responsive-menu-item">Home</div>
								</span>
							</a>
						</Link>
					</div>
					<div className="col-3 text-center" >
						<Link href="/pricing">
							<a>
								<span className={changeClass('/pricing')}>
									<i className="fas fa-tag"></i>
									<div className="app-responsive-menu-item">Pricing</div>
								</span>
							</a>
						</Link>
					</div>
					<div className="col-3 text-center" >
						<Link href="/resources">
							<a>
								<span className={changeClass('/resources')}>
									<i className="fal fa-video"></i>
									<div className="app-responsive-menu-item">Resources</div>
								</span>
							</a>
						</Link>
					</div>
					<div className="col-3 text-center">
						<a href={`${hostEventUrl}/events/create`} target="_blank" rel="noreferrer" >
							<span className="nav-buttons">
								<i className="far fa-ticket-alt"></i>
								<div className="app-responsive-menu-item">Host Events</div>
							</span>
						</a>
					</div>
					{/* <div className="col-3 text-center">
						<i className="far fa-bars"></i>
						<div className="app-responsive-menu-item">More</div>
					</div> */}
				</div>
			</div>)}
			<style jsx>{`
        .app-responsive-header {
          background: rgba(55, 21, 46, 0.8);
          backdrop-filter: blur(50px);
          border-radius: 25px 25px 0px 0px;
          color: #fff;
		  bottom:${authContext.hasCookiesAccepted ? `0px` : `${cookiePopupHeight}px`};
        }

        .nav-buttons{
          color: #fff;
          text-decoration: none;
        }

        .app-responsive-header a {
			text-decoration: none;
        }

		.nav-buttons-active{
			color: #fb5850;
		  }

        .app-responsive-header i {
          font-size: 25px;
        }

        .app-responsive-header .app-responsive-menu-item {
          font-size: 12px;
        }

		.menu_items_center{
			display: flex;
			justify-content: center;
			align-items: center;
		}
      `}</style>
		</>
	);
};

export default MobileNavbar;
